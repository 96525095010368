<template>
    <div>

        <div id="main" class="map"></div>

    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import TJ from './TJ.json'

    export default {

        data() {
            return {

            }
        },

        computed: {

        },

        created() {

        },

        mounted() {

            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('main'));

            window.addEventListener('resize', () => {
                myChart.resize()
            });

            myChart.showLoading();

            myChart.hideLoading();

            myChart.on('click', function(params) {
                // 控制台打印数据的名称
                console.log("~~~~~~~~~~", params.name, params.value);
            });


            echarts.registerMap('天津', TJ);
            myChart.setOption(
                ({
                    title: {
                        text: '大标题',
                        subtext: '小标题',
                        sublink: 'https://www.baidu.com/'
                    },
                    // tooltip: {
                    //     trigger: 'item',
                    //     formatter: '{b}<br/>{c} (p / km2)'
                    // },

                    visualMap: {
                        show: false,
                        min: 0,
                        max: 10,
                        text: ['高', '低'],
                        realtime: false,
                        calculable: true,
                        inRange: {
                            color: ['#ff0000', '#00000000'],

                        },

                    },



                    geo: {
                        map: '天津',
                        roam: true,
                        label: {
                            show: false
                        },
                    },

                    series: [


                        // {
                        //     name: '天津18区人口密度',
                        //     type: 'map',

                        //     map: '天津',
                        //     roam: true,
                        //     label: {
                        //         show: true,
                        //         color: "#ff00ff"
                        //     },
                        //     data: [

                        //         {
                        //             name: '和平区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '河东区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '河西区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '南开区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '河北区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '红桥区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '东丽区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '西青区',
                        //             value: 10
                        //         },

                        //         {
                        //             name: '津南区',
                        //             value: 10
                        //         },

                        //         {
                        //             name: '北辰区',
                        //             value: 10
                        //         },

                        //         {
                        //             name: '武清区',
                        //             value: 10
                        //         },

                        //         {
                        //             name: '宝坻区',
                        //             value: 10
                        //         },

                        //         {
                        //             name: '滨海新区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '宁河区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '静海区',
                        //             value: 0
                        //         },

                        //         {
                        //             name: '蓟州区',
                        //             value: 0
                        //         },

                        //     ],

                        // },



                        {
                            name: '天津18区人口密度',
                            // type: 'scatter',
                            type: 'effectScatter',
                            coordinateSystem: 'geo',

                            // symbol: 'circle',

                            symbol: 'image://https://www.baidu.com/img/flexible/logo/pc/result.png',

                            symbolSize: 10,
                            // itemStyle: {
                            //     color: 'red'
                            // },

                            data: [

                                {
                                    name: '1111',
                                    value: [117.164143, 39.620474, 18]
                                },
                                {
                                    name: '2222',
                                    value: [117.164143, 39.120474, 18]
                                },

                            ],

                        },


                    ]
                })
            );




        },




        methods: {},



    }
</script>

<style lang="scss" scoped>
    .map {
        // border: 1px red solid;
        box-sizing: border-box;
        width: 100%;
        // height: calc(100vh - 160px)
        // height: calc(100vh - 550px);
        height: 100%;

        overflow: hidden;
    }
</style>